<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      :visible="true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      :class="{'d-none': !visibleModal }"
      @change="hideModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ getNameButtonCreateContactLead.txt }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          ref="scroll"
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <b-form
              ref="formSaveLead"
              @submit.prevent
            >
              <validation-observer
                ref="formSaveLeadValidator"
                #default="{}"
              >
                <b-row
                  v-if="showButtonImportContactsDevice===true"
                >
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      variant="outline-info"
                      block
                      class="mb-2 sw-button-action"
                      @click.stop.prevent="$emit('openModalSearchContactsDevice')"
                    >
                      {{ $t('Importar do dispositivo') }}
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Nome') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Nome')"
                      >
                        <b-form-input
                          v-model.lazy="form_data.sw025s03"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Apelido') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Apelido')"
                      >
                        <b-form-input
                          v-model.lazy="form_data.sw025s04"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      :rules="{numberVueTelInput: numberPhoneValidate }"
                      mode="eager"
                      :name="$t('Telemóvel') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Telemóvel')"
                      >
                        <vue-tel-input
                          ref="sw025s05"
                          v-model="form_data.sw025s05"
                          class="form-control"
                          inputmode="tel"
                          :mode="configPhone.mode"
                          :valid-characters-only="configPhone.validCharactersOnly"
                          :auto-format="configPhone.autoFormat"
                          :default-country="configPhone.defaultCountry"
                          :auto-default-country="configPhone.autoDefaultCountry"
                          :input-options="configPhone.inputOptions"
                          :dropdown-options="configPhone.dropdownOptions"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          @input="checkValidateNumberPhone"
                          @open="onDropdownOpenTelInput"
                          @keydown.native.tab.prevent="$event.target.blur()"
                          @blur.native.capture.prevent="() => {
                            inputValidateNumberPhone($refs.sw025s05);
                            checkPhoneExist($event)
                          }"
                        >
                          <template slot="arrow-icon">
                            {{ '\xa0' }}
                          </template>
                          <template slot="icon-right">
                            {{ '\xa0' }}
                          </template>
                        </vue-tel-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="email"
                      :name="$t('Email') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Email')"
                      >
                        <b-form-input
                          ref="email"
                          v-model="form_data.sw025s06"
                          placeholder=""
                          autocomplete="off"
                          inputmode="email"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          @blur.native.capture.prevent="checkEmailExist"
                          @keydown.native.tab.prevent="$event.target.blur()"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Para tipo de cliente / contacto  -->
                <template v-if="vars.typeUserCreateLead===0 || vars.typeUserCreateLead===1">
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('Classificação') | lowerBold"
                      >
                        <b-form-group
                          :label="$t('Classificação')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="form_data.sw025s08"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="load_data_geral.classificacaoLead"
                            :value="form_data.sw025s08"
                            append-to-body
                            :calculate-position="withPopper"
                            label="sw021s03"
                            item-text="sw021s03"
                            item-value="sw021s01"
                          >
                            <template #option="{ sw021s02, sw021s03 }">

                              <template v-if="sw021s02">
                                {{ sw021s02 }} -
                              </template>

                              {{ sw021s03 }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        :rules="{required : ((vars.typeUserCreateLead===0) ? true : false) }"
                        :name="$t('Tipo de cliente') | lowerBold"
                      >
                        <b-form-group
                          :label="$t('Tipo de cliente')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            ref="sw027s03"
                            v-model="form_data.sw027s03"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="load_data_geral.tipoClienteLead"
                            :value="form_data.sw027s03"
                            append-to-body
                            :calculate-position="withPopper"
                            :multiple="true"
                            label="sw022s02"
                            item-text="sw022s02"
                            item-value="sw022s01"
                            :selectable="checkOptionSelectedTipoCliente"
                            @input="setUpdateFormData(); updateTypeUserCreateLead();"
                          >
                            <template #option="{ sw022s02 }">
                              {{ sw022s02 }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                            <template #selected-option-container="{ option, deselect, multiple, disabled }">
                              <span
                                :key="vSelectGetOptionKey(option)"
                                class="vs__selected"
                              >
                                <slot
                                  name="selected-option"
                                  v-bind="vNormalizeOptionForSlot(option)"
                                >
                                  {{ vSelectGetOptionLabel(option) }}
                                </slot>
                                <button
                                  v-if="multiple && (option.disabled!==true)"
                                  ref="deselectButtons"
                                  :disabled="disabled"
                                  type="button"
                                  class="vs__deselect"
                                  :title="`Deselect ${vSelectGetOptionLabel(option)}`"
                                  :aria-label="`Deselect ${ vSelectGetOptionLabel(option)}`"
                                  @click="deselect(option)"
                                >
                                  <v-select-deselect />
                                </button>
                              </span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <template v-if="vars.typeUserCreateLead===0">
                    <b-row
                      v-if="vars.showFieldComprador===true"
                    >
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="$t('Estado comprador') | lowerBold"
                        >
                          <b-form-group
                            :label="$t('Estado comprador')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="form_data.sw025s07"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="load_data_geral.estadosLeadComprador"
                              :value="form_data.sw025s07"
                              append-to-body
                              :calculate-position="withPopper"
                              label="designacao"
                              item-text="designacao"
                              item-value="id"
                              :selectable="option => option.id > vars.nivelBlockEstadoComprador"
                            >
                              <template #option="{ designacao }">
                                {{ designacao }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="vars.showFieldVendedor===true"
                    >
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="$t('Estado vendedor') | lowerBold"
                        >
                          <b-form-group
                            :label="$t('Estado vendedor')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="form_data.sw025s60"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="load_data_geral.estadosLeadVendedor"
                              :value="form_data.sw025s60"
                              append-to-body
                              :calculate-position="withPopper"
                              label="designacao"
                              item-text="designacao"
                              item-value="id"
                              :selectable="option => option.id > vars.nivelBlockEstadoVendedor"
                            >
                              <template #option="{ designacao }">
                                {{ designacao }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="(vars.showFieldVendedor===true) && (perms.canCreateListing() === true)"
                    >
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Angariado')"
                        >
                          <v-select
                            v-model="form_data.sw025s11"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="load_data_geral.optionsYesNo"
                            :value="form_data.sw025s11"
                            append-to-body
                            :calculate-position="withPopper"
                            label="txt"
                            item-text="txt"
                            item-value="value"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                </template>

                <!-- Bloco :: Fonte de contacto -->
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Fonte de contacto') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Fonte de contacto')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s13"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.fonteContactos"
                          :value="form_data.sw025s13"
                          append-to-body
                          :calculate-position="withPopper"
                          label="sw023s02"
                          item-text="sw023s02"
                          item-value="sw023s01"
                          @input="fieldsExtrasFonteContacto"
                          @change="fieldsExtrasFonteContacto"
                        >
                          <template #option="{ sw023s02 }">
                            {{ sw023s02 }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Fonte contacto :: Anuncio online -->
                <b-row
                  v-if="showFonteContactoAO===true"
                >
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Tipo de anúncio') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Tipo de anúncio')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s221"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.fonteContactoTiposAnuncio"
                          append-to-body
                          :calculate-position="withPopper"
                          label="txt"
                          item-text="txt"
                          item-value="id"
                        >
                          <template #option="{ txt }">
                            {{ txt }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="showFonteContactoAO===true"
                >
                  <b-col md="12">
                    <b-form-group
                      :label="$t('ID do anúncio')"
                    >
                      <b-form-input
                        v-model="form_data.sw025s222"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Fonte contacto :: Referenciado por consultor -->
                <b-row
                  v-if="showFonteContactoC===true"
                >
                  <b-col md="12">
                    <validation-provider
                      ref="sw025s43Validation"
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Referenciado por consultor') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Consultor')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s43"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="listConsultoresApp"
                          append-to-body
                          :calculate-position="withPopper"
                          :loading="isLoadingSW025s43"
                          :filterable="false"
                          label="desc"
                          item-text="desc"
                          item-value="id"
                          @search="onSearchConsultoresAppSelect"
                        >
                          <div
                            v-if="listConsultoresApp.length > 0"
                            slot="no-options"
                          >
                            {{ $t('Sem resultados') }}
                          </div>
                          <template
                            v-if="listConsultoresApp.length === 0"
                            slot="no-options"
                          >
                            {{ $t('Digite o nome') }}
                          </template>
                          <template
                            slot="option"
                            slot-scope="option"
                          >
                            <div class="d-flex align-items-center">
                              <b-avatar
                                :src="option.photo"
                                class="mr-50"
                              />
                              <div class="detail">
                                <b-card-text class="mb-0">
                                  {{ option.desc }}
                                </b-card-text>
                                <b-card-text
                                  v-if="option.email"
                                  class="mb-0 text-muted"
                                >
                                  <small>{{ option.email }}</small>
                                </b-card-text>
                                <b-card-text
                                  v-if="option.phone"
                                  class="mb-0 text-muted"
                                >
                                  <small>{{ option.phone }}</small>
                                </b-card-text>
                              </div>
                            </div>
                          </template>
                          <template
                            slot="selected-option"
                            slot-scope="option"
                          >
                            {{ option.desc }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Fonte contacto :: Referenciado por HUB -->
                <b-row
                  v-if="showFonteContactoM===true"
                >
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Referenciado por HUB') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('HUB')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s44"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.fonteContactoHUBs"
                          append-to-body
                          :calculate-position="withPopper"
                          label="sw002s02"
                          item-text="sw002s02"
                          item-value="sw002s01"
                        >
                          <template #option="{ sw002s02 }">
                            {{ sw002s02 }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Fonte contacto :: Referenciado por lead / contacto -->
                <b-row
                  v-if="showFonteContactoL===true"
                >
                  <b-col md="12">
                    <validation-provider
                      ref="sw025s45Validation"
                      #default="{ errors }"
                      rules="required"
                      :name="`${$t('Referenciado por')} ${$tc('Lead', 1)} / ${$tc('Contacto', 1)}` | lowerBold"
                    >
                      <b-form-group
                        :label="`${$tc('Lead', 1)} / ${$tc('Contacto', 1)}`"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s45"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="listLeadsContactsApp"
                          append-to-body
                          :calculate-position="withPopper"
                          :loading="isLoadingSW025s43"
                          :filterable="false"
                          label="nome"
                          item-text="nome"
                          item-value="id"
                          @search="onSearchLeadsContactsAppSelect"
                        >
                          <div
                            v-if="listLeadsContactsApp.length > 0"
                            slot="no-options"
                          >
                            {{ $t('Sem resultados') }}
                          </div>
                          <template
                            v-if="listLeadsContactsApp.length === 0"
                            slot="no-options"
                          >
                            {{ $t('Digite o nome') }}
                          </template>
                          <template
                            slot="option"
                            slot-scope="option"
                          >
                            <div class="d-flex align-items-center">
                              <b-avatar
                                :src="option.photo"
                                class="mr-50"
                              />
                              <div class="detail">
                                <b-card-text class="mb-0">
                                  {{ option.nome }}
                                </b-card-text>
                                <b-card-text
                                  v-if="option.email"
                                  class="mb-0 text-muted"
                                >
                                  <small>{{ option.email }}</small>
                                </b-card-text>
                                <b-card-text
                                  v-if="option.phone"
                                  class="mb-0 text-muted"
                                >
                                  <small>{{ option.phone }}</small>
                                </b-card-text>
                                <b-card-text
                                  v-if="option.tipo"
                                  class="mb-0 text-muted"
                                >
                                  <small>{{ option.tipo }}</small>
                                </b-card-text>
                              </div>
                            </div>
                          </template>
                          <template
                            slot="selected-option"
                            slot-scope="option"
                          >
                            {{ option.nome }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Fonte contacto :: Sites imobiliarios -->
                <b-row
                  v-if="showFonteContactoSI===true"
                >
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('Sites imobiliários') | lowerBold"
                    >
                      <b-form-group
                        :label="$t('Sites imobiliários')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="form_data.sw025s63"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.fonteContactoSitesImobiliarios"
                          append-to-body
                          :calculate-position="withPopper"
                          label="sw066s02"
                          item-text="sw066s02"
                          item-value="sw066s01"
                        >
                          <template #option="{ sw066s02 }">
                            {{ sw066s02 }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- Bloco :: Fonte de contacto -->

              </validation-observer>
            </b-form>
          </b-container>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 sw-button-action"
            type="submit"
            @click.stop.prevent="saveForm"
          >
            {{ $t('Guardar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-1 sw-button-action"
            @click.stop.prevent="clearForm"
          >
            {{ $t('Limpar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="sw-button-action"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

        <b-overlay
          :show="isLoading"
          no-wrap
        />

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BFormGroup, BRow, BCol, BFormInput, BSidebar, BContainer, BButton, BForm, BOverlay, BCardText, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction, VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { configPhone } from '@core-custom/utils/phone'
import { numberVueTelInput } from '@validations-custom'
import checkNested from 'check-nested'
import crmLeadsFormModule from '@store-modules/crm/leads/form'
import modelSW001 from '@store-modules/users/data'
import crmLeadsDataModule from '@store-modules/crm/leads/data'
import atob from 'atob'
import btoa from 'btoa'
import { configSettingsScroll } from '@core-custom/utils/ui'
import {
  mixFormGeral,
  mixEventsInputPhone,
  mixValidateContactsForm,
  mixFormTabGeral,
  mixEventsSelectCustom,
  mixEventsModals,
  mixFormFonteContacto,
} from './mixins'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    VueTelInput: oVueTelInput,
    BSidebar,
    BContainer,
    BButton,
    VuePerfectScrollbar,
    BOverlay,
    BCardText,
    BAvatar,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    mask,
    Ripple,
  },
  mixins: [
    sizeIconsAction,
    mixFormGeral,
    mixEventsInputPhone,
    mixValidateContactsForm,
    mixFormTabGeral,
    mixEventsSelectCustom,
    mixEventsModals,
    mixFormFonteContacto,
    VeeValidateLoadLocale,
  ],
  data() {
    return {
      visibleModal: true,
    }
  },
  computed:
    {
      ...mapGetters('crm_leads_form_modalCreate', ['form_data', 'load_data_geral', 'vars', 'perms']),
      getNameButtonCreateContactLead() {
        let oButton = {}
        if (this.$can('new', 'crm_leads_contact') && this.$can('new', 'crm_leads')) {
          oButton = {
            createType: 'all',
            txt: `${this.$t('Novo Contacto / Lead')}`,
          }
        } else if (this.$can('new', 'crm_leads_contact')) {
          oButton = {
            createType: 'onlyContact',
            txt: `${this.$t('Novo Contacto')}`,
          }
        } else if (this.$can('new', 'crm_leads')) {
          oButton = {
            createType: 'onlyLead',
            txt: `${this.$t('Novo Lead')}`,
          }
        }

        return oButton
      },
      showButtonImportContactsDevice() {
        if (this.$store.state.capacitor.isNative === true) {
          return true
        }
        if (process.env.VUE_APP_SHOW_ACTION_DEVICE === 'true') {
          return true
        }

        return false
      },
    },
  async mounted() {
    try {
      this.isLoading = true

      await this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setUpdateFormData`, null)

      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/getDataFormBase`, { keyReg: '' }).catch(async error => {
        this.errorLoadingPage = true
        this.showMsgErrorRequest(error)
        this.hideModal()
      })

      if (this.errorLoadingPage === false) {
        await this.getDataFormExtraCurrentUser()
      }

      if (this.errorLoadingPage === false) {
        await this.fieldsLoadDataDefault()

        if (this.getNameButtonCreateContactLead.createType === 'onlyContact') {
          await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setTypeUserCreateLead`, this.vars.typeLeadsIDs.contact)
        } else if (this.getNameButtonCreateContactLead.createType === 'onlyLead') {
          await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setTypeUserCreateLead`, this.vars.typeLeadsIDs.lead)
        } else {
          await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setTypeUserCreateLead`, this.vars.typeLeadsIDs.contact)
        }

        await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setLoaded`)

        await this.$nextTick()

        this.isLoading = false
      }
    } catch (err) {
      this.showMsgErrorRequest(new Error(this.$t('Problema ao carregar a informação')))
      this.hideModal()
    }
  },
  beforeCreate() {
    this.$root.$on('crmLeadsForm::showMsgDetailRequest', (data, resolve) => {
      this.showMsgDetailRequest(data.pIcon || '', data.pDetail || '', resolve)
    })
  },
  beforeDestroy() {
    this.$root.$off('crmLeadsForm::showMsgDetailRequest')

    this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setUpdateFormData`, null)
    this.resetClearFieldsFonteContacto()
    this.$refs.formSaveLeadValidator.reset()
  },
  methods: {
    clearForm() {
      this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setUpdateFormData`, null)
      this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setImportedIsMobile`, false)
      this.resetClearFieldsFonteContacto()
      this.fieldsLoadDataDefault()
      this.$refs.formSaveLeadValidator.reset()
    },
    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setIsSavingData`, true)
        await self.$refs.formSaveLeadValidator.validateWithInfo()
          .then(async ({
            isValid, errors,
          }) => {
            if (isValid) {
              this.isLoading = true

              this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setExecuteActionShare`, '')

              this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setCreateLeadContactSource`, 'dashboard')

              await this.saveFormFinish()
            } else {
              throw errors
            }
          })
          .catch(async error => {
            this.isLoading = false
            this.showMsgErrorRequestForm(error)
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setIsSavingData`, false)
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveFormFinish() {
      // Verifica se o numero de telefone e email estao preenchidos
      let bHavePhoneEmail = false

      if (checkNested(this.form_data, 'sw025s05') && (this.form_data.sw025s05 !== '')) {
        bHavePhoneEmail = true
      }

      if (checkNested(this.form_data, 'sw025s06') && (this.form_data.sw025s06 !== '')) {
        bHavePhoneEmail = true
      }

      if (bHavePhoneEmail === false) {
        throw new Error(this.$t('O campo do {phone} ou {email} é obrigatório', {
          phone: `<b>${this.$t('telemóvel')}</b>`,
          email: `<b>${this.$t('email')}</b>`,
        }))
      }

      // Verifica se este registo já se encontra duplicado
      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/checkExistAllMyLeads`, this.form_data).then(async res => {
        if (res.error === 1) {
          if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
            const actionModal = new Promise(resolve => {
              this.$root.$emit('crmLeadsForm::showMsgDetailRequest', { pIcon: 'warning', pDetail: res.alerts }, resolve)
            })

            await actionModal.then(result => {
              if (result === false) {
                throw new Error('')
              }
            })
          }
        }
      }).catch(error => {
        throw error
      })

      if (this.vars.executeActionShare === '') {
        // Verifica se o email está preenchido
        if ((this.form_data !== undefined) && (this.form_data !== null) && ('sw025s06' in this.form_data) && (this.form_data.sw025s06 !== '')) {
          //
        } else {
          await this.$swal({
            title: `${this.$t('A sua base de dados é o seu negócio!')}`,
            html: `${this.$t('Não preencheu o email.')}<br>${this.$t('Tem a certeza que pretende continuar?')}`,
            icon: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Guardar sem email'),
            cancelButtonText: this.$t('Voltar'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value !== true) {
              throw new Error('')
            }
          })
        }
      }

      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/save`).then(async resSave => {
        if (resSave.save === true) {
          this.isLoading = false

          await this.showMsgSuccessRequest({ message: resSave.msgSuccessSave }).then(async () => {
            if (this.messageInfo !== '') {
              await this.showMsgSuccessRequest({ message: this.messageInfo }).then(async () => {
                this.hideModal()
              })
            } else {
              this.hideModal()
            }
          })
        }
      }).catch(error => {
        throw error
      })
    },
    async getDataFormExtraCurrentUser() {
      const currentUser = this.$store.getters['auth/currentUser']

      if (checkNested(currentUser, 'extras.basic') && (currentUser.extras.basic === 1)) {
        //
      } else {
        await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/getListUsersTeam`, { tabSelected: 'partilhadas' }).then(async res => {
          if (res === true) {
            const userSelected = this.load_data_geral.teamUsersCreate.find(o => o.id === atob(currentUser.id))
            let teamTabsCreateSelected = ''

            if (checkNested(userSelected, 'id')) {
              this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                field: 'idConsultorShare',
                value: userSelected,
              })

              await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/getSeparadoresByUserTeam`, { user: btoa(userSelected.id) }).then(() => {
                if (this.load_data_geral.teamTabsCreate.length > 0) {
                  if (checkNested(currentUser, 'extras.cargos') && (currentUser.extras.cargos.includes(0))) {
                    // Se for Leader, cria no tabulador "Leads do leader"
                    teamTabsCreateSelected = this.load_data_geral.teamTabsCreate.find(o => o.tab === 'leads_do_leader')
                  } else {
                    // Membro, cria no tabulador "Minhas"
                    teamTabsCreateSelected = this.load_data_geral.teamTabsCreate.find(o => o.tab === 'minhas_leads')
                  }

                  if (!checkNested(teamTabsCreateSelected, 'tab')) {
                    teamTabsCreateSelected = this.load_data_geral.teamTabsCreate.find(o => o.tab !== undefined)
                  }
                }
              })

              if (teamTabsCreateSelected === '') {
                this.errorLoadingPage = true
                this.showMsgErrorRequest({ message: this.$t('Não tem permissões para criar o contacto / lead') })
                this.hideModal()
              } else {
                this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                  field: 'separadorLead',
                  value: teamTabsCreateSelected,
                })
              }
            } else {
              this.errorLoadingPage = true
              this.showMsgErrorRequest({ message: this.$t('Não tem permissões para criar o contacto / lead') })
              this.hideModal()
            }
          } else {
            this.errorLoadingPage = true
            this.showMsgErrorRequest({ message: this.$t('Problema ao obter o responsável') })
            this.hideModal()
          }
        }).catch(async error => {
          this.errorLoadingPage = true
          this.showMsgErrorRequest(error)
          this.hideModal()
        })
      }
    },
    hideModal() {
      this.$emit('closeModal')
    },
    setUpdateFormData() {
      this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setUpdateFormData`, this.form_data)
    },
    async fieldsLoadDataDefault() {
      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s05', value: '' })
      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
        field: 'sw025s11',
        value: this.load_data_geral.optionsYesNo.find(o => Number(o.value) === 2),
      })
      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
        field: 'sw025s08',
        value: this.load_data_geral.classificacaoLead.find(o => Number(o.sw021s01) === 4),
      })
    },
    updateTypeUserCreateLead() {
      if (checkNested(this.form_data, 'sw027s03') && (this.form_data.sw027s03.length > 0)) {
        this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setTypeUserCreateLead`, 0)
      } else {
        this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/setTypeUserCreateLead`, 1)
      }
    },
    setVisibleModal(val) {
      this.visibleModal = val
    },
    chooseContact(contact, replaceInfo = false) {
      try {
        if ((this.vars.importedIsMobile === false) || (replaceInfo === true)) {
          if (this.vars.importedIsMobile === true) {
            this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s03', value: '' })
            this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s04', value: '' })
            this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s05', value: '' })
            this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s06', value: '' })
          }

          if ((contact !== null) && (contact !== undefined)) {
            if (('id' in contact) && (contact.id !== '')) {
              // Nome + Apelido
              if (('name' in contact) && (contact.name !== '')) {
                const aNameFull = contact.name.split(' ')

                if (aNameFull.length > 0) {
                  if (aNameFull[0] !== '') {
                    this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s03',
                      value: aNameFull[0],
                    })
                    aNameFull.splice(aNameFull, 1)
                  }
                }

                if (aNameFull.length > 0) {
                  this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                    field: 'sw025s04',
                    value: aNameFull.join(' '),
                  })
                }
              }

              // Emails
              if (('emails' in contact) && (contact.emails.length > 0)) {
                contact.emails.forEach((o, index) => {
                  if (index === 0) {
                    this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s06',
                      value: o.address,
                    })
                  }
                })
              }

              // Contactos
              if (('phones' in contact) && (contact.phones.length > 0)) {
                contact.phones.forEach((o, index) => {
                  if (index === 0) {
                    this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s05',
                      value: o.number,
                    })
                  }
                })
              }
            }
          }

          this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setImportedIsMobile`, true)
        } else {
          this.$swal({
            title: '',
            html: `${this.$t('Pretende subsituir os contactos existentes por este contacto que selecionou?')}`,
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Importar'),
            cancelButtonText: this.$t('Cancelar'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.chooseContact(contact, true)
            }
          })
        }
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao importar o contacto.') })
      }
    },
  },
  setup() {
    const NAMESPACE_SW025_FORM = 'crm_leads_form_modalCreate'
    const NAMESPACE_SW025_FORM_DATA = 'crm_leads_data_modalCreateData'
    const NAMESPACE_SW001_DATA = 'crm_leads_sw001_modalCreateData'

    if (!store.hasModule(NAMESPACE_SW025_FORM)) {
      store.registerModule(NAMESPACE_SW025_FORM, crmLeadsFormModule)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM)) store.unregisterModule(NAMESPACE_SW025_FORM)
      })
    }

    if (!store.hasModule(NAMESPACE_SW025_FORM_DATA)) {
      store.registerModule(NAMESPACE_SW025_FORM_DATA, crmLeadsDataModule)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM_DATA)) store.unregisterModule(NAMESPACE_SW025_FORM_DATA)
      })
    }

    if (!store.hasModule(NAMESPACE_SW001_DATA)) {
      store.registerModule(NAMESPACE_SW001_DATA, modelSW001)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW001_DATA)) store.unregisterModule(NAMESPACE_SW001_DATA)
      })
    }

    // Caso o utilizador tenha permissoes para aceder ao separador "Partilhadas", reecaminha para esta pagina
    const currentUser = store.getters['auth/currentUser']

    if ((currentUser !== null) && (currentUser !== undefined)) {
      if ('extras' in currentUser) {
        if (('perm' in currentUser.extras) && (Object.values(currentUser.extras.perm).includes('partilhadas_C'))) {
          store.dispatch(`${NAMESPACE_SW025_FORM}/setParamsUrl`, { tab: 'partilhadas' })
        }
      }
    }

    store.dispatch(`${NAMESPACE_SW025_FORM}/setTypeAction`, 'new-lead')

    const isLoading = ref(false)

    return {
      NAMESPACE_SW025_FORM,
      NAMESPACE_SW025_FORM_DATA,
      NAMESPACE_SW001_DATA,

      configPhone,
      numberVueTelInput,
      isLoading,
      errorLoadingPage: false,

      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
.vti__search_box {
  width: 100% !important;
}
</style>
