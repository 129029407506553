var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{class:{'d-none': !_vm.visibleModal },attrs:{"sidebar-class":"sidebar-lg","visible":true,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":_vm.hideModal},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getNameButtonCreateContactLead.txt)+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":_vm.sizeIconsAction},on:{"click":hide}})],1)]),_c('vue-perfect-scrollbar',{ref:"scroll",staticClass:"b-sidebar-scrollarea-form",attrs:{"settings":_vm.configSettingsScroll}},[_c('b-container',{staticClass:"pt-2 pb-0 pl-2 pr-2",attrs:{"fluid":""}},[_c('b-form',{ref:"formSaveLead",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"formSaveLeadValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.showButtonImportContactsDevice===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 207, 232, 0.15)'),expression:"'rgba(0, 207, 232, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-2 sw-button-action",attrs:{"variant":"outline-info","block":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('openModalSearchContactsDevice')}}},[_vm._v(" "+_vm._s(_vm.$t('Importar do dispositivo'))+" ")])],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Nome'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nome')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.form_data.sw025s03),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s03", $$v)},expression:"form_data.sw025s03"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Apelido'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Apelido')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.form_data.sw025s04),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s04", $$v)},expression:"form_data.sw025s04"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":{numberVueTelInput: _vm.numberPhoneValidate },"mode":"eager","name":_vm._f("lowerBold")(_vm.$t('Telemóvel'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telemóvel')}},[_c('vue-tel-input',{ref:"sw025s05",staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"inputmode":"tel","mode":_vm.configPhone.mode,"valid-characters-only":_vm.configPhone.validCharactersOnly,"auto-format":_vm.configPhone.autoFormat,"default-country":_vm.configPhone.defaultCountry,"auto-default-country":_vm.configPhone.autoDefaultCountry,"input-options":_vm.configPhone.inputOptions,"dropdown-options":_vm.configPhone.dropdownOptions},on:{"input":_vm.checkValidateNumberPhone,"open":_vm.onDropdownOpenTelInput},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return $event.target.blur()},"!blur":function($event){$event.preventDefault();return (function () {
                          _vm.inputValidateNumberPhone(_vm.$refs.sw025s05);
                          _vm.checkPhoneExist($event)
                        })($event)}},model:{value:(_vm.form_data.sw025s05),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s05", $$v)},expression:"form_data.sw025s05"}},[_c('template',{slot:"arrow-icon"},[_vm._v(" "+_vm._s('\xa0')+" ")]),_c('template',{slot:"icon-right"},[_vm._v(" "+_vm._s('\xa0')+" ")])],2)],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"email","name":_vm._f("lowerBold")(_vm.$t('Email'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{ref:"email",class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","inputmode":"email"},nativeOn:{"!blur":function($event){$event.preventDefault();return _vm.checkEmailExist($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return $event.target.blur()}},model:{value:(_vm.form_data.sw025s06),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s06", $$v)},expression:"form_data.sw025s06"}})],1)]}}],null,true)})],1)],1),(_vm.vars.typeUserCreateLead===0 || _vm.vars.typeUserCreateLead===1)?[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Classificação'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Classificação')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.classificacaoLead,"value":_vm.form_data.sw025s08,"append-to-body":"","calculate-position":_vm.withPopper,"label":"sw021s03","item-text":"sw021s03","item-value":"sw021s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var sw021s02 = ref.sw021s02;
                        var sw021s03 = ref.sw021s03;
return [(sw021s02)?[_vm._v(" "+_vm._s(sw021s02)+" - ")]:_vm._e(),_vm._v(" "+_vm._s(sw021s03)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s08),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s08", $$v)},expression:"form_data.sw025s08"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":{required : ((_vm.vars.typeUserCreateLead===0) ? true : false) },"name":_vm._f("lowerBold")(_vm.$t('Tipo de cliente'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Tipo de cliente')}},[_c('v-select',{ref:"sw027s03",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.tipoClienteLead,"value":_vm.form_data.sw027s03,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"label":"sw022s02","item-text":"sw022s02","item-value":"sw022s01","selectable":_vm.checkOptionSelectedTipoCliente},on:{"input":function($event){_vm.setUpdateFormData(); _vm.updateTypeUserCreateLead();}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var sw022s02 = ref.sw022s02;
return [_vm._v(" "+_vm._s(sw022s02)+" ")]}},{key:"selected-option-container",fn:function(ref){
                        var option = ref.option;
                        var deselect = ref.deselect;
                        var multiple = ref.multiple;
                        var disabled = ref.disabled;
return [_c('span',{key:_vm.vSelectGetOptionKey(option),staticClass:"vs__selected"},[_vm._t("selected-option",[_vm._v(" "+_vm._s(_vm.vSelectGetOptionLabel(option))+" ")],null,_vm.vNormalizeOptionForSlot(option)),(multiple && (option.disabled!==true))?_c('button',{ref:"deselectButtons",staticClass:"vs__deselect",attrs:{"disabled":disabled,"type":"button","title":("Deselect " + (_vm.vSelectGetOptionLabel(option))),"aria-label":("Deselect " + (_vm.vSelectGetOptionLabel(option)))},on:{"click":function($event){return deselect(option)}}},[_c('v-select-deselect')],1):_vm._e()],2)]}}],null,true),model:{value:(_vm.form_data.sw027s03),callback:function ($$v) {_vm.$set(_vm.form_data, "sw027s03", $$v)},expression:"form_data.sw027s03"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1),(_vm.vars.typeUserCreateLead===0)?[(_vm.vars.showFieldComprador===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Estado comprador'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Estado comprador')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.estadosLeadComprador,"value":_vm.form_data.sw025s07,"append-to-body":"","calculate-position":_vm.withPopper,"label":"designacao","item-text":"designacao","item-value":"id","selectable":function (option) { return option.id > _vm.vars.nivelBlockEstadoComprador; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var designacao = ref.designacao;
return [_vm._v(" "+_vm._s(designacao)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s07),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s07", $$v)},expression:"form_data.sw025s07"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.vars.showFieldVendedor===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Estado vendedor'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Estado vendedor')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.estadosLeadVendedor,"value":_vm.form_data.sw025s60,"append-to-body":"","calculate-position":_vm.withPopper,"label":"designacao","item-text":"designacao","item-value":"id","selectable":function (option) { return option.id > _vm.vars.nivelBlockEstadoVendedor; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var designacao = ref.designacao;
return [_vm._v(" "+_vm._s(designacao)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s60),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s60", $$v)},expression:"form_data.sw025s60"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),((_vm.vars.showFieldVendedor===true) && (_vm.perms.canCreateListing() === true))?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Angariado')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.optionsYesNo,"value":_vm.form_data.sw025s11,"append-to-body":"","calculate-position":_vm.withPopper,"label":"txt","item-text":"txt","item-value":"value"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s11),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s11", $$v)},expression:"form_data.sw025s11"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)],1)],1):_vm._e()]:_vm._e()]:_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Fonte de contacto'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Fonte de contacto')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.fonteContactos,"value":_vm.form_data.sw025s13,"append-to-body":"","calculate-position":_vm.withPopper,"label":"sw023s02","item-text":"sw023s02","item-value":"sw023s01"},on:{"input":_vm.fieldsExtrasFonteContacto,"change":_vm.fieldsExtrasFonteContacto},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var sw023s02 = ref.sw023s02;
return [_vm._v(" "+_vm._s(sw023s02)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s13),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s13", $$v)},expression:"form_data.sw025s13"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1),(_vm.showFonteContactoAO===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Tipo de anúncio'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Tipo de anúncio')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.fonteContactoTiposAnuncio,"append-to-body":"","calculate-position":_vm.withPopper,"label":"txt","item-text":"txt","item-value":"id"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s221),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s221", $$v)},expression:"form_data.sw025s221"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.showFonteContactoAO===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ID do anúncio')}},[_c('b-form-input',{attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.form_data.sw025s222),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s222", $$v)},expression:"form_data.sw025s222"}})],1)],1)],1):_vm._e(),(_vm.showFonteContactoC===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"sw025s43Validation",attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Referenciado por consultor'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Consultor')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listConsultoresApp,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.isLoadingSW025s43,"filterable":false,"label":"desc","item-text":"desc","item-value":"id"},on:{"search":_vm.onSearchConsultoresAppSelect},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"src":option.photo}}),_c('div',{staticClass:"detail"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(option.desc)+" ")]),(option.email)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.email))])]):_vm._e(),(option.phone)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.phone))])]):_vm._e()],1)],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.desc)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s43),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s43", $$v)},expression:"form_data.sw025s43"}},[(_vm.listConsultoresApp.length > 0)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")]):_vm._e(),(_vm.listConsultoresApp.length === 0)?_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Digite o nome'))+" ")]):_vm._e()],2)],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.showFonteContactoM===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Referenciado por HUB'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('HUB')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.fonteContactoHUBs,"append-to-body":"","calculate-position":_vm.withPopper,"label":"sw002s02","item-text":"sw002s02","item-value":"sw002s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var sw002s02 = ref.sw002s02;
return [_vm._v(" "+_vm._s(sw002s02)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s44),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s44", $$v)},expression:"form_data.sw025s44"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.showFonteContactoL===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"sw025s45Validation",attrs:{"rules":"required","name":_vm._f("lowerBold")(((_vm.$t('Referenciado por')) + " " + (_vm.$tc('Lead', 1)) + " / " + (_vm.$tc('Contacto', 1))))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":((_vm.$tc('Lead', 1)) + " / " + (_vm.$tc('Contacto', 1)))}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listLeadsContactsApp,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.isLoadingSW025s43,"filterable":false,"label":"nome","item-text":"nome","item-value":"id"},on:{"search":_vm.onSearchLeadsContactsAppSelect},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"src":option.photo}}),_c('div',{staticClass:"detail"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(option.nome)+" ")]),(option.email)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.email))])]):_vm._e(),(option.phone)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.phone))])]):_vm._e(),(option.tipo)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.tipo))])]):_vm._e()],1)],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.nome)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s45),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s45", $$v)},expression:"form_data.sw025s45"}},[(_vm.listLeadsContactsApp.length > 0)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")]):_vm._e(),(_vm.listLeadsContactsApp.length === 0)?_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Digite o nome'))+" ")]):_vm._e()],2)],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.showFonteContactoSI===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm._f("lowerBold")(_vm.$t('Sites imobiliários'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Sites imobiliários')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.load_data_geral.fonteContactoSitesImobiliarios,"append-to-body":"","calculate-position":_vm.withPopper,"label":"sw066s02","item-text":"sw066s02","item-value":"sw066s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var sw066s02 = ref.sw066s02;
return [_vm._v(" "+_vm._s(sw066s02)+" ")]}}],null,true),model:{value:(_vm.form_data.sw025s63),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s63", $$v)},expression:"form_data.sw025s63"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex p-1 justify-content-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 sw-button-action",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 sw-button-action",attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clearForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Limpar'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"sw-button-action",attrs:{"variant":"outline-secondary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return hide($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Fechar'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }